<template>
    <div class="post">
        <p>{{$t('post_headline_' + this.doktype )}}</p>
        <h2>{{$t('post_title_'+ this.action + '_' + this.doktype )}}</h2>

        <p>{{$t('post_description_'+ this.action + '_' + this.doktype )}}</p>

        <div class="post-form">
            <div v-show="error" class="error">
                <p v-for="(err,idx) in error" :key="idx">{{err}}</p></div>
            <p v-if="doktype===0">
                <label>
                    <span>Spotlight</span>
                    <input type="checkbox"
                           v-model="postData.top"
                           value="1"
                           :checked="postData.top"
                           autocomplete="off"
                           class="sr-only"><span class="nice_checkbox"></span>
                </label>
            </p>

            <p class="form-fieldwrap post-category" v-if="categories[doktype].length > 1">
                <label>{{ $t('form_category') }}</label>
                <select v-model="postData.categories" required>
                    <option value="">{{ $t('form_choose_category') }}</option>
                    <option v-for="cat in categories[doktype]" :value="cat.uid" :key="cat.uid">{{ cat.title }}</option>
                </select>
            </p>
            <p class="post-text form-fieldwrap">
                <label>{{ $t('form_title') }}</label>
                <input type="text" required minlength="3" :maxlength="maxLength > 0 ? maxLength : false" :placeholder="$t('form_title_placeholder')" v-model="postData.title" />
                <small v-if="maxLength > 0">max. {{ maxLength }} Zeichen</small>
            </p>
            <template v-if="doktype === 0">
                <p class="post-text form-fieldwrap">
                    <label>{{$t('news_teaser')}}</label>
                    <textarea rows="2" cols="25" :maxlength="maxLength > 0 ? maxLength : false" :placeholder="$t('news_teaser_placeholder')" v-model="postData.teaser"></textarea>
                </p>
            </template>

            <p class="post-text form-fieldwrap">
                <label>{{ $t('form_bodytext') }}</label>
                <textarea rows="6" cols="25" required :maxlength="maxLength > 0 ? maxLength : false" :placeholder="$t('form_bodytext_placeholder')" v-model="postData.bodytext"></textarea>
                <small v-if="maxLength > 0">max. {{ maxLength }} Zeichen</small>
            </p>

            <p class="form-fieldwrap" v-show="postData.categories===2 || postData.categories===3"><label>{{$t('form_price')}} {{$t('optional')}}</label>
                <input type="number" min="0" step="0.1" :placeholder="$t('form_price_placeholder')" v-model="postData.price">
            </p>

            <template v-if="doktype === 3">
                <div class="m-y-2">
                    <p class="form-fieldwrap">
                        <label>{{ $t('form_start') }}</label>
                        <input type="datetime-local" v-model="postData.start" :placeholder="$t('form_start_placeholder')" />
                    </p>
                    <p class="form-fieldwrap">
                        <label>{{ $t('form_end') }}</label>
                        <input type="datetime-local" v-model="postData.end" :placeholder="$t('form_end_placeholder')" />
                    </p>
                    <p>
                        <label>
                            <span>{{ $t('form_fullday') }}</span>
                            <input type="checkbox"
                                   v-model="postData.fullDay"
                                   value="1"
                                   :checked="postData.fullDay"
                                   autocomplete="off"
                                   class="sr-only"><span class="nice_checkbox"></span>
                        </label>
                    </p>
                    <p class="form-fieldwrap">
                        <label>{{ $t('form_location') }}</label>
                        <input type="text" v-model="postData.location" :placeholder="$t('form_location_placeholder')" />
                    </p>
                    <p class="form-fieldwrap">
                        <label>{{ $t('form_organizer') }}</label>
                        <input type="text" v-model="postData.organizer" :placeholder="$t('form_organizer_placeholder')" />
                    </p>
                    <p class="form-fieldwrap">
                        <label>{{ $t('form_price') }}</label>
                        <input type="number" min="0" step="0.1" v-model="postData.price" :placeholder="$t('form_price_placeholder')" />
                    </p></div>
            </template>

            <p v-if="action === 'update'">{{$t('form_image_update_info')}}</p>

            <p class="form-fieldwrap">
                <label>{{ $t('form_mainimage') }}</label>
                <span class="form-file">{{ $t('form_mainimage_placeholder') }}<input type="file" ref="file" class="form-file" @change="fileSelected" accept="image/*"></span>
            </p>

            <div v-if="previewTitle" class="file-preview"><img :src="previewTitle" /></div>

            <p class="form-fieldwrap">
                <label>{{ $t('form_gallery') }} {{ $t('optional') }}</label>
                <span class="form-file">{{ $t('form_gallery_placeholder') }}<input type="file" ref="file" class="form-file" @change="galleryChanged" multiple accept="image/*"></span>
            </p>
            <div v-if="gallery" class="file-preview file-preview--gallery"><img :src="src" v-for="src in gallery" :key="src" /></div>
            <p class="submit">
                <button @click="submit" class="button button--primary button--submit" :disabled="dataSend" :class="{'is-loading': dataSend}">
                    {{$t('post_submit_' + this.action)}} <i class="icon-load" v-show="dataSend"></i>
                </button>
            </p>
        </div>
    </div>
</template>

<script>
// import Header from '../../components/layouts/Header';
// import store from "@/utils/store";
import api, { ApiDefaults } from '../../utils/api_call';
import { ADD_ARTICLE, UPDATE_ARTICLE } from '../../utils/store/actions/general';
import { mapGetters } from 'vuex';
import EventsBus from '../../utils/EventsBus';

export default {
	name: "KappPostsNew",
	data() {
		return {
			listScrollable: true,
			postData: {
				doktype: 0,
				title: '',
                teaser: '',
				bodytext: '',
				categories: '',
				top:0,
				file: null,
				gallery: [],
				start: null,
				end: null,
				fullDay: false,
				price: null,
				location: '',
                organizer: ''
			},
			maxLength: 0,
			dataSend: false,
			previewTitle: false,
			gallery: [],
            error: []
		};
	},
	components: {},
	props: {
		doktype: {
			type: Number,
            default: 0
        },
		data : {
			type: [Object,Boolean],
            default: false
        },
        action: {
			type: String,
            default : 'create'
        },
		date: {
			type: [Object,Boolean],
			default: false
		}
	},
	computed: {
        categories: function(){
			return {
				4: [
					{uid: 1, title: 'Allgemein'},
					{uid: 2, title: 'Suche'},
					{uid: 3, title: 'Biete'},
					{uid: 4, title: 'Events'}
				],
                3 : [
					{uid: 9, title: 'Veranstaltung'}
                ],
				0: [
					{uid: 6, title: 'Hausintern'},
					{uid: 5, title: 'Pressemitteilung'}
				]
			}
        },
        ...mapGetters([ 'getProfile' ])
	},
	methods: {
		updatePostData: function(data){
			this.postData = Object.assign({},data);
			this.postData.categories = data.categories[0].uid;
			this.postData.bodytext = this.$filters.stripTags(this.postData.bodytext);
			this.postData.teaser = data.teaser;
			this.postData.doktype = this.doktype || 0;
			this.postData.file = null;
			this.postData.gallery = [];
			if( data.images.length > 0) {
				this.previewTitle = data.images[ 0 ].url;
				this.postData.gallery = [];
				if( data.images.length > 1 ) {
					for( let i = 1; i < data.images.length; i++ )
						this.gallery.push(data.images[i].url);
				}
				delete this.postData.images;
			}
			if( data.start ){
				let d = new Date();
				d.setTime( data.start * 1000 );
				this.postData.start = d.format('Y-m-jTH:i')
            }
			if( data.end ){
				let d = new Date();
				d.setTime( data.end * 1000 );
				this.postData.end = d.format('Y-m-jTH:i')
			}
        },
		galleryChanged: function ( evt ) {
			this.gallery = [];
			if ( evt.target.files.length > 0 ) {
				for ( let f = 0; f < evt.target.files.length; f++ ) {
					let img = evt.target.files[ f ];
					this.postData.gallery.push(img);
					this.gallery.push(URL.createObjectURL(img));
				}
			}
			else {
				this.postData.gallery = [];
				this.gallery = [];
			}
		},
		fileSelected: function ( evt ) {
			if(evt.target.files.length) {
				this.postData.file = evt.target.files[ 0 ];
				this.previewTitle = URL.createObjectURL(this.postData.file);
			}
			else {
				this.postData.file = null;
				this.previewTitle = null;
            }
		},
		submit: function () {
			if( this.postData.categories === '' && this.doktype !== 3 ) {
				this.error.push( 'Sie müssen eine Kategorie auswählen' );
            }
			if( this.postData.title.length < 4 ) {
				this.error.push( 'Sie müssen einen Titel angeben' );
            }
			if( this.postData.bodytext.length < 4 ) {
				this.error.push( 'Sie müssen eine Beschreibung angeben' );
			}

			if( this.error.length > 0 ) {
				return;
            }

			this.dataSend = true;
			let postData = new FormData(),
				apiParams = {},
				endpoint = 0;
			postData.append('tx_c3json_jsondataplugin[controller]', 'JsonDataNews');
			postData.append('tx_c3json_jsondataplugin[action]', this.action );

			if( this.action === 'update' )
				postData.append('tx_c3json_jsondataplugin[news][__identity]', this.postData.uid);


			postData.append('tx_c3json_jsondataplugin[news][type]', this.doktype);
			postData.append('tx_c3json_jsondataplugin[news][title]', this.postData.title);
			postData.append('tx_c3json_jsondataplugin[news][teaser]', this.postData.teaser);
			postData.append('tx_c3json_jsondataplugin[news][bodytext]', this.postData.bodytext);

			if( this.categories[this.doktype].length > 1) {
				postData.append('tx_c3json_jsondataplugin[news][categories]', this.postData.categories.toString());
			}
			else if(this.categories[this.doktype].length === 1){
				postData.append('tx_c3json_jsondataplugin[news][categories]', this.categories[this.doktype][0]['uid']);
            }

			let start = null, end = null;
			if( this.postData.start !== null || this.postData.end !== null) {
				start = new Date(this.postData.start);
				start = start.getTime()/1000;
                end = new Date(this.postData.end);
				end = end.getTime()/1000;
			}

			switch( this.doktype ){
				// Event
                case 3:
					endpoint = 6;
					postData.append('tx_c3json_jsondataplugin[news][isEvent]','1');
					postData.append('tx_c3json_jsondataplugin[news][locationSimple]', this.postData.location);
					postData.append('tx_c3json_jsondataplugin[news][organizerSimple]', this.postData.organizer);
					if( parseInt(this.postData.price) > 0)
					postData.append('tx_c3json_jsondataplugin[news][price]', parseInt(this.postData.price) );

					postData.append('tx_c3json_jsondataplugin[news][datetime]', start);
                    postData.append('tx_c3json_jsondataplugin[news][eventEnd]', end);
                    postData.append('tx_c3json_jsondataplugin[news][fullDay]', this.postData.fullDay );

					break;

                // Bulletin
                case 4:
					endpoint = 8;
					if( parseInt(this.postData.price) > 0 ){
						postData.append('tx_c3json_jsondataplugin[news][price]', this.postData.price);
                    }
                    break;

				// News
				default:
					endpoint = 2;
					postData.append('tx_c3json_jsondataplugin[news][istopnews]', (this.postData.top ? '1' : '0'));
                    break;
			}

			postData.append('file', this.postData.file);
			for ( let i = 0; i < this.postData.gallery.length; i++ ) {
				postData.append('gallery[]', this.postData.gallery[ i ]);
			}

			apiParams = Object.assign({}, ApiDefaults,
				{
					headers: {'Content-Type': 'multipart/formdata'},
                    timeout: 50000,
					params: {id: endpoint},
					method: 'post',
					data: postData
				}
			);

			api(apiParams)
				.then(( response ) => {
					this.dataSend = false;
					if ( response.status === 200 ) {
						let article = response.data.news;

						// if( this.doktype === 0 ){
						// 	article.top = this.postData.top;
                        // }
                        //
						// if( postData.datetime ){
						// 	article.datetime = this.postData.datetime;
                        // }

						let target = 'news';
						if( this.doktype === 3 ) {
							target = 'events';
                        }
						else if ( this.doktype === 4 ) {
							target = 'bulletin';
                        }

                        if( this.action === 'create') {
                            this.$store.commit(ADD_ARTICLE, {newArticle: article, target: target });
							this.postData = {
								doktype: 0,
								title: '',
								bodytext: '',
                                teaser: '',
								categories: 0,
								top:0,
								file: null,
								gallery: [],
								start: null,
								end: null,
								fullDay: false,
								price: null,
								location: '',
								organizer: ''
							};
							this.gallery= [];
							this.previewTitle = null;
                        }
                        else if ( this.action === 'update' ) {
                            this.$store.commit(UPDATE_ARTICLE, {article: article, target: target });
                        }

						EventsBus.trigger('overlay:close');

					}
				});
		}
	},
    created(){
		if( this.data ){
			this.updatePostData(this.data);
        }
		EventsBus.on('lightbox:close',() => {
			this.previewTitle = false;
			this.gallery = [];
			this.postData = {
				doktype: 0,
				title: '',
				teaser: '',
				bodytext: '',
				categories: '',
				top: 0,
				file: null,
				gallery: [],
				start: null,
				end: null,
				fullDay: false,
				price: null,
				location: '',
				organizer: ''
			};
		});

		// this.$refs.light$emit('close').then(() => this.postData = {
		// 	doktype: 0,
		// 	title: '',
		// 	teaser: '',
		// 	bodytext: '',
		// 	categories: '',
		// 	top: 0,
		// 	file: null,
		// 	gallery: [],
		// 	start: null,
		// 	end: null,
		// 	fullDay: false,
		// 	price: null,
		// 	location: '',
		// 	organizer: ''
		// });
    },
    watch:{
		'postData.categories': function(){
			this.error = [];
        },
		'postData.title': function(){
			this.error = [];
		},
		'postData.bodytext': function(){
			this.error = [];
		},
		data: function(data){
			this.updatePostData(data)
        },
		date : function(val){
			let start = val.format('Y-m-j');
			start += 'T';
			let d = new Date();
			start += d.format('H:i');
			this.postData.start = start;
		}
    }
};
</script>

<style scoped>

/*
.button.button--submit {
    position: relative;
}

.button.button--submit.is-loading {
    background-color: #394452;
    border-color: #394452;
}

.button.button--submit.is-loading .icon-load {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: inline-block;
    width: 30px;
    height: 30px;
}

.button.button--submit.is-loading .icon-load::after {
    content: " ";
    display: block;
    width: 26px;
    height: 26px;
    margin: 0px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
*/</style>
